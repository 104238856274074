import { months } from "Components/Atoms/A_Reusable/ActualDate";
import { format, getDate, getMonth, sub } from "date-fns";

export const setEventsDelay = ( events ) => {
   let mult = 300000 // 5 minutos
   let preDelay = 500  // 0.5 seconds previous
   let delay = 0
   let count = 0

   if ( events.length < 200 ){
      mult = 15000 // 15s
   }
   else if ( events.length < 350 ){
      mult = 30000 // 30s
   } else if ( events.length < 500 ){
      mult = 45000 // 45s
   }
   delay = mult / events.length

   events.forEach( event => {
      event.delay = (delay*count) + preDelay;
      count++;
   } )

   return mult;
}

export const filterRelevantEvents = ( events ) => {
      
   let relvntCount = 1;

   events.sort( function(a, b) { 
      return ( a.magnitude - b.magnitude ) * -1
   });

   events.some((e, index) => {
      if ( e.magnitude >= 4.9 ) e.relevant = 1;
      return index >= 10;
   })

   events.sort( function(a, b) { // sorts by date
      if (a.time > b.time){
         return 1;
      } 
      if ( a.time < b.time ){
         return -1;
      }
      return 0;
   })

   events.forEach( event => { // add relevants
      if ( event.relevant > 0 ){ // event.cdi ??
         event.relevant = relvntCount;
         relvntCount++;
      } else {
         event.relevant = 0
      }
      // insular events
      if ( event.longitude <= -81.1 && event.latitude >= 12.2 ){
         event.insular = true;
      } 
   } )

   // if ( !events.some( e => e.relevant > 0 ) ){
   //    events.some( (event, index) => {
   //       event.relevant = index + 1;
   //       return index >= 2;
   //    } )
   // }

}

const setDefaultTimeRange = () => {
   const currentDate = new Date();
   const prevWeekDay = sub( currentDate, { days: 7 } ); // subs 7 days to the current date

   return {
      startDate: format ( prevWeekDay, "yyyy-MM-dd'T00:00:00'"), 
      endDate: format ( currentDate , "yyyy-MM-dd'T23:59:59'"), 
   } 
}
export const defaultTimeRange = setDefaultTimeRange();

export const formatDateToText = ({ startDate, endDate }) =>{
   startDate = new Date( startDate );
   endDate = new Date( endDate );

   let startMonth = getMonth( startDate );
   let endMonth = getMonth( endDate );
   const formatStartMonth = endMonth > startMonth ? `de ${ months[ startMonth ].toLowerCase() }` : ""

   return `${ getDate( startDate ) } ${ formatStartMonth } al ${ getDate( endDate ) } de ${ months[ endMonth ].toLowerCase() } de ${ endDate.getFullYear() }`;
}

export const cdiIntensities = [
   { cdi: 2, text: "Apenas sentido" },
   { cdi: 3, text: "Sentido levemente" },
   { cdi: 4, text: "Sentido ampliamente" },
   { cdi: 5, text: "Sentido fuertemente" },
   { cdi: 6, text: "Daño leve" },
   { cdi: 7, text: "Daño moderado" },
   { cdi: 8, text: "Daño severo" },
]

export const formatCdi = ( cdi ) => {
   return `${ cdi } (${ cdiIntensities.find( e => e.cdi === cdi )?.text })`;
}