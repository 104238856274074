import logo from 'assets/icons/Reusable/Logo_SGC.svg';
import government from 'assets/icons/Reusable/LOGO-COLOMBIA-POTENCIA.png'
import logo_White from 'assets/icons/Reusable/logoWhite.svg';
import LegalHeader from 'Components/Atoms/A_Home/LegalHeader';
import { Link } from 'react-router-dom';

export default function HomeHeader () {

    return (
      <>
         <LegalHeader />
         <div className = "HmLogos">
            <div className="HmIcon" title="Colombia Potencia Mundial de la Vida">
               <img
                  src = { government }
                  alt = "logo-government"
                  className = "logo-sgc"
               />
            </div>
            <Link className = "HmIcon" role="link" aria-label="Página del SGC" to="/" title="SGC">
               <img
                  src = { logo }
                  alt = "logo-sgc"
                  className = "logo-sgc"
               />
               <img 
                  src = { logo_White }
                  className = "logo-sgc-white"
                  alt = "logo-White"
               />
            </Link>
         </div>
      </>
    );
}