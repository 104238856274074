import contraste from 'assets/icons/Home/side_menu/ICN_contraste.svg';
import letterCase from 'assets/icons/Home/side_menu/ICN_font-control.svg';
import Lsenas from 'assets/icons/Home/side_menu/ICN_lenguaje-senas.svg';

import { Tooltip } from "@mui/material";
import { UseAccessibility } from "hooks/UseAccesibility";

const HmSideMenu = () => {
    const {
        font,
        contrast,
        switchFont, switchContrast 
    } = UseAccessibility();

    return (
       <div className = "HmSideMenu">
         <Tooltip disableInteractive title = "Control de la fuente" placement = "bottom">
            <div className = "HmSideHref">
               <div onClick = { switchFont } >
                  <img alt = "fuente" src = { letterCase } />
                  <span>
                     { font.text }
                  </span>
               </div>
            </div>
         </Tooltip>
         <Tooltip disableInteractive title = "Control del contraste" placement = "bottom">
            <div className = "HmSideHref" onClick = { switchContrast }>
               <div >
                  <img alt = "contraste" src = { contraste } />
                  <span>
                        { contrast.text }
                  </span>
               </div>
            </div>
         </Tooltip>
         <Tooltip disableInteractive title = "Lengua de señas SGC" placement = "bottom">
            <a className = "HmSideHref" href = "https://youtube.com/playlist?list=PL005t5w5hBe-COYSU9gMONlvoA0pK9Jz8" rel = "noopener noreferrer" target = "_blank">
               <div>
                  <img alt="Lsenas" src = { Lsenas } height={40}/>
                  <span>Lengua de Señas</span>
               </div>
            </a>
         </Tooltip>
        </div>
    )

}

export default HmSideMenu;