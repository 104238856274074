import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { loadHomeEvent } from 'redux/actionsCreators';
import HomeSearchBar from '../Molecules/M_Home/HomeSearchBar';
import HmSideMenu from '../Atoms/A_Home/HmSideMenu';
import Loader from "Components/Pages/Loader";
import '../../Styles/S-Reusable/Animations/animated.css'
import ErrorRequest from "Components/Atoms/A_Reusable/ErrorRequest";
import HomeMainCarousel from "Components/Molecules/M_Home/HomeMainCarousel";
import HomeEventsCarousel from "Components/Molecules/M_Home/HomeEventsCarousel";
import LoaderEvents from "Components/Organisms/O_EQViewer/LoaderEvents";
import HomeChat from "Components/Atoms/A_Home/HomeChat";
import HomeHeader from "Components/Templates/T_Home/HomeHeader";
import HomeMenu from "Components/Molecules/M_Home/HomeMenu";
import HomeFooter from "Components/Templates/T_Home/HomeFooter";
import { HomeService } from "api/Services/ReqHome";
import HomeSections from "Components/Organisms/O_Home/HomeSections";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const { show_excuses_reload_page } = ErrorRequest;

function Home() {
   
	const bannerHome = useSelector( state => state.homeEvent );
   const dispatch = useDispatch();

	const loadInitialData = async () => {

		document.title = 'SGC - Inicio';

		try {
			const { data } = await HomeService.getBanner();
			data ? dispatch( loadHomeEvent( data ) ) : show_excuses_reload_page ();
		} catch {
			show_excuses_reload_page ();
		}

	}

	useEffect(() => {
		loadInitialData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div id="SGCHome">
			{
				bannerHome[0] ? (

               <div className="HmContainer">
                  
                  <header className="HomeHeader" aria-label="Header">
                     <HomeHeader />
                     <HomeMenu />
                  </header>

                  <main className="HomeMain" role="main" aria-label="Contenido">

                     <h1 id="SGCTitle" hidden> 
                        Servicio Geológico Colombiano - SGC
                     </h1>

                     <aside className="HomeAside" aria-label="Opciones">
                        <HomeChat />
                        <HmSideMenu />
                     </aside>
                     
                     <section className="HmMainBanner" aria-label="Banner Principal">
                        <HomeSearchBar />
                        <HomeMainCarousel />
                     </section>

                     <section className="HmEventsBanner" aria-label="Slider Eventos Geológicos" >
                        <LoaderEvents home/>
                        <HomeEventsCarousel />
                     </section>

                     <HomeSections />
                  </main>

                  <footer className = "HmFooter">
                     <HomeFooter hideShadowBox />
                  </footer>
               </div>
				) : <Loader />
			}
		</div>
	)
}

export default Home;